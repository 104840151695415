/*
 * modal windows
 */
.modal{
  padding-top: 250px;

  input, select {
    font-size: 12px;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    color: #636b6f;
  }
}
.modal .close {
  position: absolute;
  right: 25px;
  top: 15px;
}
.modal .close:focus {
  outline: 0;
}
.modal .close:hover {
  transition: all 0.3s;
  opacity: 1;
  color: crimson;
}
.modal .modal-dialog {
  transition: all .2s !important;
}
.modal .modal-content {
  border: 1px solid #f2f6f7;
  border-radius: 3px;
  box-shadow: none;
}
.modal .modal-header {
  text-align: center;
  border-bottom: 0;
  padding: 25px 25px 0 25px;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
}

.modal form label {
  color: #636b6f;
  font-family: 'Raleway', sans-serif;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
}

.modal .modal-header h5 {
  font-family: 'Montserrat';
  text-transform: uppercase;
  letter-spacing: 0.06em;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  color: #8b91a0;
}
.modal .modal-header p {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: grey;
  font-weight: 300;
  line-height: 1.7em;
  color: #8b91a0;
}
.modal .modal-body {
  box-shadow: none;
  padding: 25px;
  padding-top: 0;
  white-space: normal;
}
.modal .modal-footer {
  border-top: none;
  box-shadow: none;
  margin-top: 0;
  padding: 25px;
  padding-top: 0;
}
.modal .drop-shadow {
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36) !important;
}
.modal.fade {
  opacity: 1 !important;
}