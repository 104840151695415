// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");
// Variables
@import 'variables';
// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import "app/modal";
.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

html {
  zoom: 80%;
}

html,
body {
  background-color: #fff;
  color: #636b6f;
  font-family: 'Raleway', sans-serif;
  font-size: 13px;
  font-weight: 100;
  height: 100vh;
  margin: 0;
}

label {
  font-weight: bold;
}

.full-height {
  height: 100vh;
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.position-ref {
  position: relative;
}

.top-right {
  position: absolute;
  right: 10px;
  top: 18px;
}

.links>a {
  color: #636b6f;
  padding: 0 25px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: .1rem;
  text-decoration: none;
  text-transform: uppercase;
}

input,
button {
  border-radius: 0px !important;
}

input {
  font-weight: 400;
}

.content {
  text-align: center;
}

.title {
  font-size: 84px;
}

.m-b-md {
  margin-bottom: 30px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: 100%;
  background-color: #000;
}